import {AfterViewInit, Component, Input, isDevMode, OnInit} from '@angular/core';
import {Coupon} from '../../data/Coupon';
import {User} from '../../data/user';
import {ServerService} from '../../services/server.service';
import {DeleteDialogComponent} from '../dialog/delete-dialog/delete-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarRef, TextOnlySnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

@Component({
  selector: 'app-list-component',
  templateUrl: './list-component.component.html',
  styleUrls: ['./list-component.component.scss']
})
export class ListComponentComponent implements OnInit, AfterViewInit {

  // tslint:disable-next-line:no-input-rename
  @Input('coupon') coupon: Coupon;
  // tslint:disable-next-line:no-input-rename
  @Input('user') user: User;
  expand = false;
  usedCards = [];

  constructor(private server: ServerService, public dialog: MatDialog, private snackBar: MatSnackBar, private router: Router) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.updateCoupons();
  }

  hasBarcode(): boolean {
    return this.coupon.barcode != null && this.coupon.barcode !== '';
  }

  isPersonal(): boolean {
    try {
      const back = this.isOwner();
      return back && this.coupon.personal === 1;
    } catch (e) {
      return false;
    }
  }

  isOwner(): boolean {
    if (this.user != null) {
      return (this.user.userId === this.coupon.userId) || this.user.power >= 50;
    } else {
      return false;
    }
  }

  toggleBarcode(): void {
    this.expand = !this.expand;
    console.log(this.coupon);
    console.log(this.user);
  }

  public dateExpired(date: string): boolean {
    return false;
  }

  public getNiceDate(expires: string): string {
    let back = expires;
    back = back.split(' ')[0];
    // @ts-ignore
    back = back.replaceAll('-', '.');
    return back;
  }

  async toCheckout(): Promise<void> {
    const url = 'addCouponToCheckout.php?&couponId=' + this.coupon.couponId;
    // const done = await this.server.getSuccess(url);
    // #fail#insertErrorColumn
    try {
      const data = await this.server.get(url);
      this.openSnackBar('Coupon was added to checkout');
      this.coupon.checkout = 1;
    } catch (e) {
      if (e.startsWith('#fail#insertError')) {
        this.openSnackBar('You need to add a card first').onAction().subscribe(() => {
          this.router.navigate(['dashboard']);
        });
      } else {
        this.openSnackBar(e);
        if (isDevMode()) {
          console.log(this.server.getUrl());
          console.log(e);
        }
      }
    }
  }

  async removeFromCheckout(): Promise<void> {
    const url = 'removeCouponToCheckout.php?&couponId=' + this.coupon.couponId;
    // const done = await this.server.getSuccess(url);
    // #fail#insertErrorColumn
    try {
      const data = await this.server.get(url);
      this.openSnackBar('Coupon was removed from checkout');
      this.coupon.checkout = 0;
    } catch (e) {
      if (e.startsWith('#fail#insertError')) {
        this.openSnackBar('You need to add a card first').onAction().subscribe(() => {
          this.router.navigate(['dashboard']);
        });
      } else {
        this.openSnackBar(e);
        if (isDevMode()) {
          console.log(this.server.getUrl());
          console.log(e);
        }
      }
    }
  }

  async useCoupon(): Promise<void> {
    const url = 'useCoupon.php?&couponId=' + this.coupon.couponId;
    // const done = await this.server.getSuccess(url);
    try {
      await this.server.get(url);
      this.coupon.show = false;
    } catch (e) {
      console.log(this.server.getUrl());
    }
  }

  async deleteCoupon(): Promise<void> {

    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const url = 'delCoupon.php?&couponId=' + this.coupon.couponId;
        // const done = await this.server.getSuccess(url);
        this.server.get(url).then(data => {
          this.coupon.show = false;
        });
      }
    });
  }

  openSnackBar(message: string): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(message, 'Ok', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  async updateCoupons() {
    try {
      let data = await this.server.get('getUsedOnCards.php?&couponId=' + this.coupon.couponId);
      if (data !== '') {
        data = JSON.parse(data);
        for (const c of data) {
          // console.log(c);
          this.usedCards.push(c);
        }
      }
    } catch (e) {
      this.openSnackBar(e);
    }

  }
}
