export class User {
  userId: number;
  mail: string;
  username: string;
  version: string;
  power: number;


  constructor(json:any) {
    this.userId = parseInt(json.userId);
    this.power = parseInt(json.power);
    this.username = json.username
    this.version = json.version
    this.mail = json.mail
  }
}
