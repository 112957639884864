import {Injectable} from '@angular/core';
import {UserAuthService} from './user-auth.service';
import {HttpClient} from '@angular/common/http';
import {User} from '../data/user';

@Injectable({
  providedIn: 'root'
})
export class ServerService {

  private server = 'https://api.jan-schuettken.de/couponwallet/';
  private lastUrl: string;

  constructor(private http: HttpClient, public authService: UserAuthService) {
  }

  protected async getSuccess(urlExtension: string): Promise<boolean> {
    const data = await this.getResponse(urlExtension);
    return !data.startsWith('#fail#');
  }

  async get(urlExtension: string, setSession: boolean = true): Promise<string> {
    const data = await this.getResponse(urlExtension, setSession);
    if (data.toString().startsWith('#fail#')) {
      throw data;
    } else {
      return data;
    }

  }

  async getResponse(urlExtension: string, setSession: boolean = true): Promise<string> {
    const untouchedUrl = urlExtension;
    let url = this.server + urlExtension;
    if (setSession) {
      url = this.server + urlExtension.replace('?', '?session=' + this.getSession());
    }
    this.lastUrl = url;
    let data = await this.http.get(url, {responseType: 'text'}).toPromise();
    // console.log(url);
    // console.log(data);
    if (data.startsWith('#fail#')) {
      if (data.startsWith('#fail#session error')) {
        if (await this.authService.loginWithToken()) {
          if (setSession) {
            url = this.server + untouchedUrl.replace('?', '?session=' + this.getSession());
          } else {
            url = this.server + untouchedUrl;
          }
          this.lastUrl = url;
          data = await this.http.get(url, {responseType: 'text'}).toPromise();
          return data;
        } else {
          throw new Error('Login not possible');
        }
      }
    }
    return data;
  }

  getSession(): string {
    return this.authService.getSession();
  }

  getUrl(): string {
    return this.lastUrl;
  }
}
