import {Injectable, isDevMode} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {ServerService} from './server.service';

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {

  session = '';
  private serverUrl = 'https://api.jan-schuettken.de/couponwallet/';

  constructor(private http: HttpClient,
              private router: Router,
              private cookieService: CookieService) {
    if (this.session === '') {
      this.session = this.getSession();
    }
  }

  setSession(session: string): void {
    this.session = session;
    this.cookieService.set('SessionId', session, 1); // expires in one day
    if (isDevMode()) {
      console.log(session);
    }
  }

  getSession(): string {
    if (this.cookieService.check('SessionId')) {
      return this.cookieService.get('SessionId');
    } else {
      return null;
    }
  }

  getLoginToken(): string {
    if (this.cookieService.check('token')) {
      return this.cookieService.get('token');
    } else {
      return null;
    }
  }

  setLoginToken(token: string): void {
    this.cookieService.set('token', token, 365); // expires in one year
  }

  async register(username: string, password: string, email: string): Promise<boolean> {
    const url = this.serverUrl + 'addUser.php?user=' + username + '&password=' + password + '&mail=' + email;
    const data = await this.http.get(url, {responseType: 'text'}).toPromise();
    // console.log(data);
    // console.log(url);
    if (data.startsWith('#fail#')) {
      throw new Error(data);
    } else {// login true
      // const session = data.toString();
      // this.setSession(session);
      return await this.loginWithUsername(username, password);
    }
  }

  async loginWithUsername(username: string, password: string): Promise<boolean> {
    const url = this.serverUrl + 'login.php?user=' + username + '&password=' + password;
    const data = await this.http.get(url, {responseType: 'text'}).toPromise();
    // console.log(data);
    // console.log(url);
    if (data.startsWith('#fail#')) {
      return false;
    } else {// login true
      const session = data.toString();
      this.setSession(session);
      await this.pullLoginToken();
      return true;
    }
  }

  async pullLoginToken(): Promise<boolean> {
    const url = this.serverUrl + 'getToken.php?session=' + this.getSession();
    const data = await this.http.get(url, {responseType: 'text'}).toPromise();
    // console.log(data);
    // console.log(url);
    if (data.startsWith('#fail#')) {
      return false;
    } else {// login true
      const session = data.toString();
      this.setLoginToken(JSON.parse(session).token);
      // console.log(this.pullLoginToken());
      return true;
    }
  }

  async loginWithToken(): Promise<boolean> {
    const token = this.cookieService.get('token');
    if (token != null && token.length > 5) {
      const url = this.serverUrl + 'login.php?token=' + token;
      const data = await this.http.get(url, {responseType: 'text'}).toPromise();
      if(isDevMode()) {
        console.log(data);
        console.log(url);
      }
      if (data.startsWith('#fail#')) {
        return false;
      } else {// login true
        const session = data.toString();
        this.setSession(session);
        return true;
      }
    } else {
      return false;
    }
  }

  async logout() {
    // this.cookieService.delete('SessionId');
    // this.router.navigate(['/']);
    // console.log('logout');
    if (!await this.loginWithToken()) {
      this.forceLogout(false);
    }
  }

  forceLogout(route = true): void {
    this.cookieService.delete('SessionId');
    this.cookieService.delete('token');
    if (route) {
      this.router.navigate(['/login']).then();
    }
  }
}
