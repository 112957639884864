import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SidebarComponent} from './sidebar/sidebar.component';
import {NavbarComponent} from './navbar/navbar.component';
import {FooterComponent} from './footer/footer.component';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ListComponentComponent} from './list-component/list-component.component';
import {NgxBarcodeModule} from 'ngx-barcode';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {MatChipsModule} from '@angular/material/chips';
import { DeleteDialogComponent } from './dialog/delete-dialog/delete-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { ForgotPasswordDialogComponent } from './dialog/forgot-password-dialog/forgot-password-dialog.component';
import {MatInputModule} from '@angular/material/input';
import { ImprintComponent } from './imprint/imprint.component';
import { ImprintDialogComponent } from './dialog/imprint-dialog/imprint-dialog.component';
import { DataProtectionComponent } from './data-protection/data-protection.component';
import { DataProtectionDialogComponent } from './dialog/data-protection-dialog/data-protection-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgbModule,
        NgxBarcodeModule,
        MDBBootstrapModule,
        MatChipsModule,
        MatDialogModule,
        MatButtonModule,
        MatInputModule
    ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ListComponentComponent,
    DeleteDialogComponent,
    ForgotPasswordDialogComponent,
    ImprintComponent,
    ImprintDialogComponent,
    DataProtectionComponent,
    DataProtectionDialogComponent,
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ListComponentComponent,
  ]
})
export class ComponentsModule {
}
