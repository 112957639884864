/* tslint:disable:radix */
export class Coupon {
  couponId: number;
  description: string;
  expires: string;
  starts: string;
  type: string;
  barcode: string;
  multiUsage: number;
  storeId: number;
  storeName: string;
  cardId: number;
  cardName: string;
  used: number;
  checkout: number;
  show: boolean;
  userId: number;
  personal: number;
  xFold: number;
  expiresDate: number;

  constructor(json: any) {
    if (json == null) {
      return;
    }
    this.couponId = parseInt(json.couponId);
    this.description = json.description;
    this.expires = json.expires;
    this.starts = json.starts;
    this.type = json.type;
    this.barcode = json.barcode;
    this.multiUsage = parseInt(json.multiUsage);
    this.storeId = parseInt(json.storeId);
    this.storeName = json.storeName;
    this.cardId = parseInt(json.cardId);
    this.cardName = json.cardName;
    this.used = parseInt(json.used);
    this.checkout = parseInt(json.checkout);
    this.show = false;
    this.userId = parseInt(json.userId);
    this.personal = parseInt(json.personal);
    this.xFold = parseInt(json.xFold);
    this.expiresDate = Date.parse(this.expires);
  }

  public setShow(s: boolean): void {
    this.show = s;
  }

}
