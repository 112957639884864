import {Component, OnInit} from '@angular/core';
import {Coupon} from '../../data/Coupon';
import {ApiService} from '../../services/api.service';
import {ServerService} from '../../services/server.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  coupons: Coupon[] = [];
  activeCoupon: Coupon = new Coupon(null);
  activeCard: any;
  activeIndex = -1;
  hasCheckout = false;
  checkoutCreated = false;

  constructor(private api: ApiService, private server: ServerService, private router: Router) {
  }

  ngOnInit(): void {
    this.api.getCheckoutCoupons().then((c) => {
      if (c == null) {
        return;
      }
      if (c.length > 0) {
        this.hasCheckout = true;
      }
      this.coupons = c;
      this.activeCoupon = this.coupons[0];
      console.log(c);
    })
      .catch();
    this.api.loadCheckOut().then((c) => {
      if (c != null) {
        this.activeCard = c[0];
        this.checkoutCreated = true;
      }
    });
  }

  async checkoutDone(): Promise<void> {

    for (const c of this.coupons) {
      if (!await this.useCoupon(c)) {
        console.log('Error with:');
        console.log(c);
      }
    }

    await this.clearCheckout();
  }

  async nextCoupon(): Promise<void> {
    const goOn = true;
    if (this.activeIndex < 0) {
      // card
    } else {
      // coupon
    }
    if (goOn) {
      this.activeIndex++;
      if (this.activeIndex < this.coupons.length) {
        this.activeCoupon = this.coupons[this.activeIndex];
      } else {
        this.activeCoupon = new Coupon(null);
        // TODO overflow
      }
    }
  }

  async useCoupon(coupon: Coupon): Promise<boolean> {
    const url = 'useCoupon.php?&couponId=' + coupon.couponId + '&userCardId=' + this.activeCard.userCardId;
    // const done = await this.server.getSuccess(url);
    const data = await this.server.getResponse(url);
    console.log(this.server.getUrl());
    return !data.startsWith('#fail#');
  }

  public dateExpired(date: string): boolean {
    return false;
  }

  public getNiceDate(expires: string): string {
    if (expires == null) {
      return null;
    }
    let back = expires;
    back = back.split(' ')[0];
    // @ts-ignore
    back = back.replaceAll('-', '.');
    return back;
  }

  async clearCheckout() {
    const data = await this.server.getResponse('doneCheckout.php?');
    if (data.startsWith('#fail#')) {

    } else {
      this.router.navigate(['dashboard']).then();
    }
  }
}
