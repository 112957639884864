import {Injectable, isDevMode} from '@angular/core';
import {ServerService} from './server.service';
import {Coupon} from '../data/Coupon';
import {User} from '../data/user';
import {StorageMap} from '@ngx-pwa/local-storage';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private server: ServerService, private storage: StorageMap) {
  }

  async logout() {
    await this.server.getResponse('logout.php?');
    await this.server.authService.forceLogout(true);
  }

  async getYourself(): Promise<User> {
    const tmp = await this.saveGetOffline('getYourself.php?', 'yourself');
    return new User(tmp);
  }

  async getCoupons(): Promise<Coupon[]> {
    const tmp = await this.saveGetOffline('getCoupons.php?', 'coupons');
    const back = [];
    for (const t of tmp) {
      back.push(new Coupon(t));
    }

    return back;
  }

  async getCheckoutCoupons(): Promise<Coupon[]> {
    return this.saveGetOffline('getCheckoutCoupons.php?', 'checkoutCoupons');
  }

  async loadCards(): Promise<any[]> {
    return this.saveGetOffline('getCards.php?', 'cards');
  }

  async loadCheckOut(): Promise<void> {
    return this.saveGetOffline('getCheckout.php?', 'checkout');
  }

  async loadAllCards(): Promise<any[]> {
    return this.saveGetOffline('getAllCards.php?', 'alCards');
    // const url = 'getCards.php?';
    // const data = await this.server.getResponse(url);
    // this.cards = JSON.parse(data);
  }

  async loadStores(): Promise<any[]> {
    return this.saveGetOffline('getStores.php?', 'stores');
    // const url = 'getCards.php?';
    // const data = await this.server.getResponse(url);
    // this.cards = JSON.parse(data);
  }

  async forgotPassword(mail: string): Promise<string> {
    const url = 'forgotPassword.php?mail=' + mail;
    return await this.server.getResponse(url, false);
  }

  async changeUserInfo(user: User) {
    let url = 'changeUserInfo.php?';
    url += '&username=' + user.username;
    url += '&mail=' + user.mail;
    return await this.server.getResponse(url);
  }

  async changePassword(old: string, newPw: string) {
    let url = 'changePassword.php?';
    url += '&oldPw=' + old;
    url += '&newPw=' + newPw;
    return await this.server.getResponse(url);
  }

  private async saveGetOffline(url: string, dataSet: string): Promise<any> {
    try {
      const data = await this.server.getResponse(url);
      localStorage.setItem(dataSet, data);
      try {
        return JSON.parse(data);
      } catch (e) {
        if (isDevMode()) {
          console.log('Data is no JSON: "' + data + '"');
        }
        return null;
      }
    } catch (e) {
      if (isDevMode()) {
        console.error(e);
      }
      const data = localStorage.getItem(dataSet);
      if (data != null && data.length > 0) {
        try {
          return JSON.parse(data);
        } catch (e) {
          if (isDevMode()) {
            console.log('Data is no JSON: "' + data + '"');
          }
          return null;
        }
      } else {
        return null;
      }
    }
  }
}
