import {ApplicationRef, Injectable, OnInit} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {first} from 'rxjs/operators';
import {concat, interval} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  constructor(private updates: SwUpdate, private appRef: ApplicationRef) {

  }

  activateUpdate(updates: SwUpdate) {
    updates.available.subscribe(event => {
      // if (event.) {
      updates.activateUpdate().then(() => document.location.reload());
      // }
    });
  }

  checkForUpdatesNow() {
    this.updates.checkForUpdate().then(b => console.log('Update available: ' + b));
  }

  checkForUpdates() {
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    everySixHoursOnceAppIsStable$.subscribe(() => this.updates.checkForUpdate());
  }

  logUpdateState() {
    this.updates.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          // this.activateUpdate(this.updates);
          document.location.reload();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
        default:
          console.log(evt);
          break;
      }
    });
  }
}
