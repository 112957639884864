import {Component, OnInit} from '@angular/core';
import {UpdateService} from './services/update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Coupon Wallet';

  constructor(private update: UpdateService) {
  }

  ngOnInit(): void {
    this.update.logUpdateState();
    this.update.checkForUpdates();
  }


}
