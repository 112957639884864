import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ImprintDialogComponent} from '../dialog/imprint-dialog/imprint-dialog.component';
import {DataProtectionDialogComponent} from '../dialog/data-protection-dialog/data-protection-dialog.component';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  date: Date = new Date();
  public version: string;

  constructor( public dialog: MatDialog) { }

  ngOnInit() {
    this.version = 'v' + environment.version;
  }

  openImprint() {
    this.dialog.open(ImprintDialogComponent);
  }

  openDataProtection() {
    this.dialog.open(DataProtectionDialogComponent);
  }
}
