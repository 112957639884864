import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserAuthService} from '../../services/user-auth.service';
import {ServerService} from '../../services/server.service';
import {ApiService} from '../../services/api.service';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = [
  {path: '/dashboard', title: 'Dashboard', icon: 'ni-planet text-primary', class: ''},
  {path: '/checkout', title: 'Checkout', icon: 'ni-tv-2 text-blue', class: ''},
  // {path: '/maps', title: 'Maps', icon: 'ni-pin-3 text-orange', class: ''},
  // {path: '/user-profile', title: 'User profile', icon: 'ni-single-02 text-yellow', class: ''},
  {path: '/coupons', title: 'Coupons', icon: 'ni-bullet-list-67 text-red', class: ''},
  // {path: '/login', title: 'Login', icon: 'ni-key-25 text-info', class: ''},
  // {path: '/register', title: 'Register', icon: 'ni-circle-08 text-pink', class: ''}
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;
  user: any;

  constructor(private router: Router, private api: ApiService, public authService: UserAuthService) {
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
    this.api.getYourself().then((u) => this.user = u);
  }
}
